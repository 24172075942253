import { ACCOUNT_INITIALISE, LOGIN, LOGOUT } from './actions';

const accountReducer = (state, action) => {
    switch (action.type) {
        case ACCOUNT_INITIALISE: {
            const { isLoggedIn, user, userId } = action.payload;
            return {
                ...state,
                isLoggedIn,
                isInitialised: true,
                user,
                userId,
            };
        }
        case LOGIN: {
            const { user, userId } = action.payload;
            return {
                ...state,
                isLoggedIn: true,
                user,
                userId,
            };
        }
        case LOGOUT: {
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                userId: null,
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
