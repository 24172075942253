import { SUB_MENU, SUB_MENU_ACTIONS } from "./actions"
export const initialState = {
    isOpen: '',
    isHeader: '',
    url : '',
    roleAccess: '',
    type : 'subMenu'
}
const subMenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUB_MENU:
            return {
                ...state,
                isOpen: action.isOpen,
                isHeader: action.isHeader,
                url : action.url,
            }
        case SUB_MENU_ACTIONS:
            return {
                ...state,
                isOpen : action.isOpen,
                isHeader : action.isHeader,
                roleAccess : action.roleAccess   
            }
        default: { return { ...state } }
    }
}

export default subMenuReducer;



