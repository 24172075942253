import React, { useEffect } from 'react';

import {
    Fade,
    Button,
    ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Paper,
    Popper,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
} from '@mui/material';


import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';

import PersonTwoToneIcon from '@mui/icons-material/PersonTwoTone';
import LockOpenTwoTone from '@mui/icons-material/LockOpenTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import MeetingRoomTwoToneIcon from '@mui/icons-material/MeetingRoomTwoTone';

import useAuth from 'hooks/useAuth';

import { useForm } from "react-hook-form";

import { PasswordInputText, AlertMessage, AlertPopup } from 'component/Muihookform/Fields';

import { axiosClient } from 'utils/apiClient';
import serviceEndPoint from 'services/endPoints';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '350px',
        minWidth: '250px',
        backgroundColor: theme.palette.background.paper,
        paddingBottom: 0,
        borderRadius: '10px',
    },
    subHeader: {
        backgroundColor: theme.palette.grey.A400,
        color: theme.palette.common.white,
        padding: '5px 15px',
    },
    menuIcon: {
        fontSize: '1.5rem',
        color: '#000',
    },
    menuButton: {
        [theme.breakpoints.down('md')]: {
            minWidth: '50px',
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: '35px',
        }
    },
}));

const ProfileSection = () => {
    const classes = useStyles();

    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const { logout, user } = useAuth();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = React.useState(false);

    const validationSchema = Yup.object().shape({
        currentPassword: Yup.string()
            .required('Current Password is required.'),
        newPassword: Yup.string()
            .required('Confirm Password is required.')
            .notOneOf([Yup.ref('currentPassword')], "New and Current password shouldn't match."),
        confirmPassword: Yup.string()
            .required('Confirm Password is required.')
            .oneOf([Yup.ref('newPassword')], "New and confirm password must match."),
    });

    const {
        handleSubmit,
        setError,
        formState: { errors },
        reset,
        control,
    } = useForm({ mode: "onBlur" , resolver : yupResolver(validationSchema) });

    const resetPasswordDialogHandleOpen = () => {
        setOpen(false);
        setResetPasswordDialogOpen(true);
    };

    const resetPasswordDialogHandleClose = () => {
        setResetPasswordDialogOpen(false);
        reset();
    };

    //const [resetPasswordSuccessMessage, setResetPasswordSuccessMessage] = React.useState(false);
    const [resetPasswordSuccess, setResetPasswordSuccess] = React.useState(false);
    const [resetPasswordFailed, setResetPasswordFailed] = React.useState(false);

    const onSubmit = (data) => {

        axiosClient
            .put(serviceEndPoint.auth.resetpassword + '/reset-password?id=' + user.id, data)
            .then((response) => {
                if ((response.status === 200)) {
                    reset();
                    setResetPasswordSuccess(true);
                    setResetPasswordDialogOpen(false);
                }
            })
            .catch((error) => {
                if ((error.response.status === 400)) {
                    let fieldErrors = error.response.data.errorMessages;
                    Object.keys(fieldErrors).forEach((key) => {
                        setError(fieldErrors[key].fieldName, {
                            type: "serverError",
                            message: fieldErrors[key].message
                        });
                    });
                }
                else {
                    setResetPasswordFailed(true);
                }
            });
    };


    const handleLogout = async () => {
        try {
            //handleClose();
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    const handleListItemClick = (event, index) => {
        setOpen(false);
        setSelectedIndex(index);
        if (index === 4) {
            //handleLogout;
        }
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <React.Fragment>
            <Button
                className={classes.menuButton}
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="inherit"
            >
                <AccountCircleTwoToneIcon className={classes.menuIcon} />
            </Button>
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 10],
                        },
                    },
                    {
                        name: 'preventOverflow',
                        options: {
                            altAxis: true, // false by default
                        },
                    },

                ]}
            >
                {({ TransitionProps, placement }) => (
                    <Fade {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <List component="nav" className={classes.root}>
                                    <ListItem key={2} button component={Link} to='/dashboard/default' selected={selectedIndex === 1} onClick={(event) => handleListItemClick(event, 1)}>
                                        <ListItemIcon>
                                            <PersonTwoToneIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={user?.last_name} />
                                    </ListItem>
                                    <ListItem key={4} button selected={selectedIndex === 3} onClick={resetPasswordDialogHandleOpen}>
                                        <ListItemIcon>
                                            <LockOpenTwoTone />
                                        </ListItemIcon>
                                        <ListItemText primary="Reset Password" />
                                    </ListItem>
                                    <ListItem key={5} button selected={selectedIndex === 4} onClick={handleLogout}>
                                        <ListItemIcon>
                                            <MeetingRoomTwoToneIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Logout" />
                                    </ListItem>
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Fade>
                )}
            </Popper>
            <Dialog open={resetPasswordDialogOpen} maxWidth="xs" onClose={resetPasswordDialogHandleClose} aria-labelledby="form-dialog-title">
                <form id="resetPasswordForm" name="resetPasswordForm" onSubmit={handleSubmit(onSubmit)}>

                    <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <AlertMessage success={resetPasswordFailed} variant="warning" message="There might be a temporary network issue. Please try later." method={setResetPasswordFailed} />

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <PasswordInputText
                                    control={control}
                                    errors={errors}
                                    name="currentPassword"
                                    required={true}
                                    label="Current Password"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <PasswordInputText
                                    control={control}
                                    errors={errors}
                                    name="newPassword"
                                    required={true}
                                    label="New Password"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <PasswordInputText
                                    control={control}
                                    errors={errors}
                                    name="confirmPassword"
                                    required={true}
                                    label="Confirm New Password"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" variant="contained" color="primary" size="small">Reset Password</Button>
                        <Button onClick={resetPasswordDialogHandleClose} size="small" variant="contained" color="error">Cancel</Button>
                    </DialogActions>
                </form>
            </Dialog>
            <AlertPopup success={resetPasswordSuccess} variant="success" message="Your password has been reset successfully." method={setResetPasswordSuccess} />
        </React.Fragment>
    );
};

export default ProfileSection;
