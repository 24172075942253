import React from 'react';
import { lazy } from 'react';

// project imports
import Loadable from '../component/Loadable';
import MinimalLayout from '../layout/MinimalLayout';

const AuthLogin = Loadable(lazy(() => import('../views/Login')));
const AuthForgotPassword = Loadable(lazy(() => import('../views/ForgotPassword')));

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/application/login',
            element: <AuthLogin />,
        },
        {
            path: '/application/forgot-password',
            element: <AuthForgotPassword />,
        },
    ],
};

export default AuthenticationRoutes;
