import React, { useState, useEffect } from 'react';

// material-ui
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

// third-party
import rtlPlugin from 'stylis-plugin-rtl';

import { IntlProvider } from 'react-intl';

import { useSelector } from 'react-redux';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { JWTProvider } from "contexts/JWTContext";
import theme from 'themes';
import Routes from 'routes/index';
import NavigationScroll from './NavigationScroll';
import Snackbar from 'component/Snackbar';

function loadLocaleData(locale) {
  switch (locale) {
    case 'fr':
      return import('compiled-lang/fr.json');
    case 'ro':
      return import('compiled-lang/ro.json');
    case 'zh':
      return import('compiled-lang/zh.json');
    default:
      return import('compiled-lang/en.json');
  }
}

const App = () => {
  const customization = useSelector((state) => state.customization);
  const { locale, rtlLayout } = customization;
  const [messages, setMessages] = useState();

  useEffect(() => {
    loadLocaleData(locale).then(d => {
      setMessages(d.default);
    });
  }, [locale]);

  useEffect(() => {
    document.dir = rtlLayout ? 'rtl' : 'ltr';
  }, [rtlLayout]);

  const cacheRtl = createCache({
    key: rtlLayout ? 'rtl' : 'css',
    prepend: true,
    stylisPlugins: rtlLayout ? [rtlPlugin] : []
  });
  
  return (
    <React.Fragment>
      {messages && <IntlProvider
        locale={customization.locale}
        defaultLocale="en"
        messages={messages}
      >
        <NavigationScroll>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme(customization)}>
              <CssBaseline />
              <CacheProvider value={cacheRtl}>
                <JWTProvider>
                  <Routes />
                  <Snackbar />
                </JWTProvider>
              </CacheProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </NavigationScroll>
      </IntlProvider>}
    </React.Fragment>
  );
};

export default App;
