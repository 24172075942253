import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CasesOutlinedIcon from '@mui/icons-material/CasesOutlined'; //Case registration
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined'; //Data Entry
import DomainVerificationOutlinedIcon from '@mui/icons-material/DomainVerificationOutlined'; //Verification / Operations
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined'; //Report
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'; //User
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined'; //Client
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'; // General Masters
import SettingsApplicationsSharpIcon from '@mui/icons-material/SettingsApplicationsSharp'; // Configuration
import SellIcon from '@mui/icons-material/Sell';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import SettingsAccessibilityOutlinedIcon from '@mui/icons-material/SettingsAccessibilityOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default {
    DashboardIcon: HomeOutlinedIcon,
    UserIcon: AccountCircleOutlinedIcon,
    RoleIcon: SettingsAccessibilityOutlinedIcon,
    SkillIcon: LibraryBooksOutlinedIcon,
    CertificateIcon: WorkspacePremiumOutlinedIcon,
    ProjectIcon : FolderOutlinedIcon,
    GeneralMastersIcon: AccountTreeOutlinedIcon,
    SettingsApplicationsSharpIcon: SettingsApplicationsSharpIcon,
    ArrowForwardIcon : ArrowForwardIcon
};
