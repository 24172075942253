import { useEffect, useState} from 'react';
import { axiosClient } from 'utils/apiClient';
import serviceEndPoint from 'services/endPoints';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CasesOutlinedIcon from '@mui/icons-material/CasesOutlined'; //Case registration
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined'; //Data Entry
import DomainVerificationOutlinedIcon from '@mui/icons-material/DomainVerificationOutlined'; //Verification / Operations
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined'; //Report
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'; //User
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined'; //Client
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'; // General Masters
import SettingsApplicationsSharpIcon from '@mui/icons-material/SettingsApplicationsSharp'; // Configuration
import SchoolTwoToneIcon from '@mui/icons-material/SchoolTwoTone';

const icons = {
    DashboardIcon: HomeOutlinedIcon,
    CasesRegistrationIcon: CasesOutlinedIcon,
    DataEntryIcon: StorageOutlinedIcon,
    OperationsIcon: DomainVerificationOutlinedIcon,
    ReportsIcon: InsertChartOutlinedIcon,
    UserIcon: AccountCircleOutlinedIcon,
    ClientIcon: CorporateFareOutlinedIcon,
    CheckMasters: DoneAllIcon,
    GeneralMastersIcon: AccountTreeOutlinedIcon,
    SettingsApplicationsSharpIcon: SettingsApplicationsSharpIcon,
    InstitutionIcon : SchoolTwoToneIcon
};


const useMenu = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
   
  useEffect(() => {
      const fetchData = async () => {
	  try {
	      const  response = await axiosClient.get(serviceEndPoint.auth.sideMenu);
	      const items = [];
              setData(response.data.data);
	  } catch (error) {
              console.error(error)
	  }
	  setLoading(false);
      };
      fetchData();
  }, []);
    
    return {
      data,
      loading,
  };
};

export default useMenu;
