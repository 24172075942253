let authAPI = '/api/auth';
let userAPI = '/api/users';
let configurationApi = '/api/masters';
let masterApi = '/api/masters';
let roleAPI = '/api/roles';
let skillAPI = '/api/skills';
let projectAPI = '/api/projects';
let matexUserApi = '/matex2-user-service/api/v1'

export default {
    auth: {
        login: authAPI + '/login', //POST
        logout: authAPI + '/logout', //POST
        userfromtoken: authAPI + '/userfromtoken',
        loginterms: authAPI + '/loginterms',
	sideMenu: authAPI + '/sidemenu',
    },
    configuration: {
        organization: configurationApi + '/Organization',
        outgoingmail: configurationApi + '/OutgoingMailServer',
        termsandcondition: configurationApi + '/MatexTc'
    },
    masters: {
        country: masterApi + '/country',
        state: masterApi + '/state',
        district: masterApi + '/district',
        city: masterApi + '/city',
        region: masterApi + '/region',
        industry: masterApi + '/industry',
	division: masterApi + '/division',
	certificationType: masterApi + '/certification-type',
	certificationModeOfLearning: masterApi + '/certificate-mode-of-learning',
	projectType: masterApi + '/project-type',
    },
    users: {
	user: userAPI + '/user',
	userExport: userAPI + '/user/export',
        userRole: userAPI + '/userrole',
    },
    roles: {
	role: roleAPI + '/role',
    },
    skills: {
	skill: skillAPI + '/skill',
    },
    projects: {
	project: projectAPI + '/project',
    },

};

