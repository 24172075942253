import { lazy } from 'react';
import React from 'react';
import Loadable from '../component/Loadable';
import NavMotion from '../layout/NavMotion';
// import MainLayout from '../layout/MainLayout';
import GuestGuard from '../component/Auth/GuestGuard';
// import AuthGuard from '../component/Auth/AuthGuard';
import MinimalLayout from '../layout/MinimalLayout';

const AuthLogin = Loadable(lazy(() => import('../views/Login')));
const AuthForgotPassword = Loadable(lazy(() => import('../views/ForgotPassword')));

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/',
            element: <AuthLogin />,
        },
        {
            path: '/login',
            element: <AuthLogin />,
        },
        {
            path: '/forgot-password',
            element: <AuthForgotPassword />,
        },
    ],
};

export default LoginRoutes;
