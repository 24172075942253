import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import serviceEndPoint from 'services/endPoints';
import { axiosClient } from 'utils/apiClient';

import NavGroup from './NavGroup';
//import menuItem from 'menu-items';
import useMenu from 'hooks/useMenu';

const MenuList = () => {
    const {
	data,
	loading,
    } = useMenu();

    const [serviceMenuItems, setServiceMenuItems] = useState([]);
    useEffect(() => {
	if(!loading){
	    setServiceMenuItems(data);
	}
    }, [data]);
   
    if(!loading){
     //navItems = menuItem.items.map((item) => {
	const navItems = serviceMenuItems?.map((item) => {
        switch (item.type) {
        case 'group':
            return <NavGroup key={item.id} item={item} />;
        default:
            return (
                <Typography key={item.id} variant="h6" color="error" align="center">                                                     
                    Menu Items Error                                                                                                     
                </Typography>
            );
        }
    });
    return navItems;
    }
};

export default MenuList;
