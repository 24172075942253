import { combineReducers } from 'redux';
import customizationReducer from './customizationReducer';
import snackbarReducer from './snackbarReducer';
import subMenuReducer from './subMenuReducer';
import { ErrorReducer ,SearchErrorReducer }  from './errorReducer';
import LoadingReducer from './loadingReducer';


const reducer = combineReducers({
    customization: customizationReducer,
    snackbar: snackbarReducer,
    submenu : subMenuReducer,
    error : ErrorReducer,
    load  : LoadingReducer,
    search : SearchErrorReducer
});

export default reducer;
