/*
    @author : "S Kishore",
    @purpose : "This page is used to catch all kind of errors that may happen in this application". 
    @created : "09-06-2023"
*/


import * as actionTypes from './actions';

export const initialState = {
    error: '',
    place : '', // A for submit, QS for Quick input Search , D for delete, U for update,
    url : ''
};

// Here place is only for A QS 
const ErrorReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ERROR:
            return {
                ...state,
                error: action.error,
                place: action.place ? action.place : '',
                url : action.url
            };
        default:
            return state;
    }
};

// This is for Search error
export const searchinitialState = {
    search: '',
    place : '',
    status : '',
    error : '',
    url : ''
};

const SearchErrorReducer = (state = searchinitialState, action) => {
    switch (action.type) {
        case 'SEARCH':
            return {
                ...state,
                search: action?.search,
                place : action?.place,
                status : action?.status,
                error : action?.error,
                url : action?.url
            };
        default:
            return state;
    }
};


export { ErrorReducer , SearchErrorReducer } ;
