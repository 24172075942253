/*
    @author : "S Kishore",
    @purpose : "This page is used to common loading animation.
                Whenever the app is requesting the loading state will be true. After it will false". 
    @created : "09-06-2023",
*/
import * as actionTypes from './actions';

export const initialState = {
    loading: '',
    place: '',
};

const LoadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD:
            return {
                ...state,
                loading: action.loading,
                place: action.place,
            };
        default:
            return state;
    }
};


export default LoadingReducer;
