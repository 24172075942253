import React, { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import AuthGuard from 'component/Auth/AuthGuard';
import Loadable from 'component/Loadable';

const DashboardDefault = Loadable(lazy(() => import('views/Dashboard/Default')));

const GeneralMasters = Loadable(lazy(() => import('views/GeneralMasters')));

const Configuration = Loadable(lazy(() => import('views/Configuration')));
const MatexUsers = Loadable(lazy(() => import('views/MatexUsers')));
const Employees = Loadable(lazy(() => import('views/Employees')));
const Roles = Loadable(lazy(() => import('views/Roles')));
const Skills = Loadable(lazy(() => import('views/Skills')));
const Certificates = Loadable(lazy(() => import('views/Certificates')));
const Projects = Loadable(lazy(() => import('views/Projects')));


const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard/default',
            element: <DashboardDefault />,
        },
        {
            path: '/generalmasters/*',
            element: <GeneralMasters />,
        },
        {
            path: '/employees/*',
            element: <Employees />,
        },
	{
            path: '/roles/*',
            element: <Roles />,
        },
	{
            path: '/skills/*',
            element: <Skills />,
        },
	{
            path: '/certificates/*',
            element: <Certificates />,
        },
	{
            path: '/projects/*',
            element: <Projects />,
        },
        {
            path: '/matexusers/*',
            element: <MatexUsers />,
        },
        {
            path: '/configuration/*',
            element: <Configuration />,
        },
    ],
};

export default MainRoutes;
